import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { TokenModel } from '../models/token.model';
import { LoginUserModel } from '../models/loginuser.model';
import { UserModel } from '../models/user.model';
import { CompanyModel } from '../models/company.model';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private url = 'https://develop.api.tbs.galapagosislands.com/usuario/';
  headers: Headers = new Headers();

  constructor(private http: HttpClient) { }

  async login(
    email: string,
    password: string,
    company: string
  ): Promise<TokenModel> {
    const resp = await this.login2(email, password, company).toPromise();
    const token: TokenModel = {
      tokenType: '',
      id: '',
      accessToken: resp!.accessToken || '',
    };
    return token;
  }

  login2(
    email: string,
    password: string,
    company: string
  ): Observable<TokenModel> {
    const url = this.url + 'signin';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    let logUser: LoginUserModel = {
      user: email,
      password: password,
      company: company,
    };
    const body = JSON.stringify(logUser);
    return this.http.post<TokenModel>(url, body, httpOptions);
  }

  getUserData(id: string, token: string): Observable<UserModel> {
    const url = this.url + id;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.get<UserModel>(url, httpOptions);
  }

  getCompaniesList(user: string): Observable<CompanyModel[]> {
    const url = this.url + 'environments/' + user;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.get<CompanyModel[]>(url, httpOptions);
  }
}
